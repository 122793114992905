const menu = () => {
  const menuButton = document.querySelector('.js-menu-button');
  const menuList = document.querySelector('.js-menu-list');

  if (menuButton && menuList) {
    menuButton.addEventListener('click', () => {
      menuButton.classList.toggle('is-active');
      menuList.classList.toggle('is-active');
    });
  }
};

export default menu;
