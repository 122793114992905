const cookies = () => {
  const cookiesEl = document.querySelector('.js-cookies');
  if (cookiesEl) {
    const cookiesAccept = localStorage.getItem('cookiesAccept');
    if (cookiesAccept === 'true') {
      return;
    }
    cookiesEl.classList.add('is-active');
    const cookiesButton = document.querySelector('.js-cookies-btn');
    if (cookiesButton) {
      cookiesButton.addEventListener('click', (event) => {
        event.preventDefault();
        localStorage.setItem('cookiesAccept', 'true');
        cookiesEl.classList.remove('is-active');
      });
    }
  }
};

export default cookies;
