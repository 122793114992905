/*
  Project: Naturalskin
  Author: Adam Kanigowski
 */

import cookies from './modules/cookies';
import menu from './modules/menu';

document.addEventListener('DOMContentLoaded', () => {
  menu();
  cookies();
});
